<template>
	<div class="container">
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>审批中心</el-breadcrumb-item>
			<el-breadcrumb-item>我的申请</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="table">
			<el-row style="margin: 5px auto; float: right;">
				<el-button type="info" icon="el-icon-refresh" size="small" circle @click="listSelfApply"></el-button>
			</el-row>
			<el-table :data="tableData" border stripe @row-dblclick="handleView">
				<el-table-column type="index" label="#" />
				<el-table-column prop="templateName" label="申请单" />
				<el-table-column prop="unique" label="业务单号" />
				<el-table-column label="申请人">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user">
							{{ scope.row.applier?.nickname }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="applyTime" label="申请时间" width="160" />
				<el-table-column prop="finishTime" label="结束时间" width="160" />
				<el-table-column label="当前审批人">
					<template slot-scope="scope">
						<el-tag
							v-if="scope.row.auditors && scope.row.auditors.length > 0"
							size="mini"
							v-for="(auditor, index) in scope.row.auditors"
							:key="index"
							effect="dark"
							class="user"
						>
							{{ auditor?.nickname }}
						</el-tag>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column label="流程状态">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user" :type="flowableType(scope.row.status)">
							{{ flowableStatus(scope.row.status) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="handleView(1, scope.row)"
						>
							审批详情
						</el-button>
						<el-button
							size="mini"
							type="info"
							@click="handleView(2, scope.row)"
						>
							流程图
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="handleEvent(scope.row)"
							v-if="scope.row.status == 1"
						>
							撤销
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="handleEvent(scope.row)"
							v-if="scope.row.status == 5"
						>
							重新发起
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				layout="prev, pager, next, total"
				:current-page.sync="pageNo"
				@current-change="listSelfApply"
				:total="total"
			/>
		</div>
		<el-drawer
			:title="mode === 1 ? '审批详情' : '流程图'"
			:visible.sync="show"
			:modal-append-to-body="false"
			:size="mode === 1 ? '500px' : '100%'"
			direction="rtl"
			:modal="false"
			destroy-on-close
		>
			<div v-if="mode === 1">
				<process-timeline-viewer :processInstanceId="processInstanceId" />
			</div>
			<div v-if="mode === 2">
				<process-diagram-viewer />
			</div>
		</el-drawer>
	</div>
</template>
<script>
import ProcessDiagramViewer from '../../common/ProcessDiagramViewer'
import ProcessTimelineViewer from '../../common/ProcessTimelineViewer'
import mixins from '../mixins/mixins'

export default {
	components: { ProcessDiagramViewer, ProcessTimelineViewer },
	mixins: [mixins],
	data() {
		return {
			mode: 1,
			show: false,
			processInstanceId: '',
			tableData: [],
			total: 0,
			pageNo: 1,
			pageSize: 20
		}
	},
	mounted() {
		this.listSelfApply()
	},
	methods: {
		async listSelfApply() {
			const operator = sessionStorage.getItem('operator')
			const { total, list } = await this.$api.flowable.selfApply({
				operator: operator,
				pageNo: this.pageNo,
				pageSize: this.pageSize
			})
			this.total = total
			this.tableData = list
		},
		async handleView(mode, row) {
			this.mode = mode
			this.processInstanceId = row.processInstanceId
			if (mode === 1) {
				this.show = true
			}
			if (mode === 2) {
				const {
					template, 
					finishedNodeIds, 
					runningNodeIds, 
					waitingNodeIds 
				} = await this.$api.flowable.instanceInfo({
					processInstanceId: row.processInstanceId,
					taskId: row.taskId
				})
				template.process = JSON.parse(template.process)
				this.$store.state.flowable.template = template
				this.$store.state.flowable.finishedNodeIds = finishedNodeIds
				this.$store.state.flowable.runningNodeIds = runningNodeIds
				this.$store.state.flowable.waitingNodeIds = waitingNodeIds
				this.show = true
			}
		},
		async handleEvent(row) {
			const operator = sessionStorage.getItem('operator')
			if (row.status == 1) {
				await this.$api.flowable.revoke(row.processInstanceId, {
					operator: operator
				})
				this.$message.success('申请撤销成功')
				this.listSelfApply()
			}
			if (row.status == 4) {
				await this.$api.flowable.restart({
					templateId: row.templateId,
					operator: operator,
					processInstanceId: row.processInstanceId
				})
				this.$message.success('重新提交成功')
				this.listSelfApply()
			}
		},
	}
}
</script>
<style lang="less" scoped>
.container {
	.breadcrumb {
		margin-bottom: 10px;
	}
	.table {
		background-color: #fff;
		padding: 10px;
		border-radius: 5px;
	}
	.user {
		margin-right: 5px;
	}
}
</style>
